import React from 'react';
import { Button, Table } from 'react-bootstrap';
import '../App.css'

class Gallery extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            index: 1,
            direction: null
        };
    }

    render() {
        function importAll(r) {
            let images = {};
            r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
            return images;
        }

        const images = importAll(require.context('../screenshots/', false, /\.jpg$/));
        const thumbs = importAll(require.context('../thumbnails/', false, /\.jpg$/));

        let screenshots = []
        let i = 1
        for (const img in images) {
            i++
            if (images.hasOwnProperty(img)) {
                const element = <img width="75%" height="75%" alt="2" src={images[`image(${i}).jpg`]} />;
                screenshots.push(element)
            }
        }
        const MoveRight = (e) => {
            console.log("Index: " + this.state.index)
            let newindex = this.state.index
            if (this.state.index <= 53) {
                newindex++
            } else {
                newindex = 1
            }
            this.setState({
                index: newindex
            })
        }
        const MoveLeft = () => {
            console.log("Index: " + this.state.index)
            let newindex = this.state.index
            if (this.state.index >= 2) {
                newindex--
            } else {
                newindex = 54
            }

            this.setState({
                index: newindex
            })
        }
        let SetIndex = (e) => {
            console.log(e.target.alt)
            this.setState({
                index: e.target.alt
            })
        }
        let NextThumb = (pos) => {
            let nextpos = this.state.index
            if (this.state.index + pos === 55) {
                return 1
            }
            if (this.state.index + pos >= 55) {
                nextpos = 0 + pos
                return nextpos
            }

            if (this.state.index + pos <= 55) {
                nextpos = nextpos + pos
                return nextpos
            } else {
                nextpos = 1
                return nextpos
            }
        }
        let PrevThumb = (pos) => {
            let nextpos = this.state.index

            if (this.state.index > 1) {
                nextpos -= pos
                if (nextpos === -1) {
                    return 53
                }
                if (nextpos === 0) {
                    return 54
                }
                return nextpos
            }
            if (this.state.index === 1) {
                nextpos = 55 - pos
                return nextpos
            }

        }
        console.log("Gallery")
        return (
            <div className="swingintopfwd">
                <br />
                <Button className="btn-sm" onClick={MoveLeft}>Back</Button><Button className="btn-sm" onClick={MoveRight} >Next</Button><br /><br />
                <img width="95%" height="95%" alt="2" src={images[`screenshot(${this.state.index}).jpg`]} />
                <img onClick={SetIndex} className="thumb" alt={PrevThumb(3)} src={thumbs[`thumb(${PrevThumb(3)}).jpg`]} />
                <img onClick={SetIndex} className="thumb" alt={PrevThumb(2)} src={thumbs[`thumb(${PrevThumb(2)}).jpg`]} />

                <img onClick={MoveLeft} className="thumb" alt={PrevThumb(1)} src={thumbs[`thumb(${PrevThumb(1)}).jpg`]} />

                <img className="mainthumb" alt={this.state.index} src={thumbs[`thumb(${this.state.index}).jpg`]} />

                <img onClick={MoveRight} className="thumb" alt={NextThumb(1)} src={thumbs[`thumb(${NextThumb(1)}).jpg`]} />

                <img onClick={MoveRight} className="thumb" alt={NextThumb(2)} src={thumbs[`thumb(${NextThumb(2)}).jpg`]} />

                <img onClick={SetIndex} className="thumb" alt={NextThumb(3)} src={thumbs[`thumb(${NextThumb(3)}).jpg`]} />
            </div>
        );
    }
}

export default Gallery