

import React from 'react';


const Discord = props => {

  return (
    <div className="swingintopfwd">
        <br/>
        <iframe title="213232" src="https://discordapp.com/widget?id=217150452343635968&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0"></iframe>
    </div>
  )

}

export default Discord;
