import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Navigation = props => {

    return (

      <ListGroup>
        <Link href="/" to="/"><ListGroupItem>Home</ListGroupItem></Link>
        <Link href="/members" to="/members"><ListGroupItem>Members</ListGroupItem></Link>
        <Link href="/servers" to="/servers"><ListGroupItem>Server Info</ListGroupItem></Link>
        <Link href="/gallery" to="/gallery"><ListGroupItem >Gallery</ListGroupItem></Link>
        <Link href="/discord" to="/discord"><ListGroupItem >Discord</ListGroupItem></Link>
        <ListGroupItem><a href="https://steamcommunity.com/groups/JaggedCommandos" target="new">Steam Group</a></ListGroupItem>
      </ListGroup>

    );
  
}

export default Navigation;
