import React from 'react';
import { Table } from 'react-bootstrap';

const FmTw = props => {

    return (
        <div className="swingintopfwd">
            <br />
            <div className="container">
                <Table align="center" striped bordered >
                    <thead>
                        <tr>
                            <th><a href="https://www.twitch.tv/fishmissile?tt_content=text_link&tt_medium=live_embed" >FishMissile on Twitch</a></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>
                            <iframe title="Fish Twitch" src="https://player.twitch.tv/?channel=fishmissile"scrolling="no" height="378" width="620"></iframe>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default FmTw;
