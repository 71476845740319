import React from 'react';
import { Table } from 'react-bootstrap';

const FmYt = props => {

    return (
        <div className="swingintopfwd">
            <br />
            <div className="container">
                <Table align="center" striped bordered >
                    <thead>
                        <tr>
                            <th><a href="https://www.youtube.com/channel/UCOkKHq8uZMJtS0pbDAmaaAQ">FishMissile on Youtube</a></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>
                            <iframe title="Fish Youtube" width="560" height="315" src="https://www.youtube.com/embed/R6g9xIqf4Lo"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default FmYt;
