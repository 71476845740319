import React, { Component } from 'react';
import logo from '../images/logo2.png'

class Header extends Component {
  render() {
    return (
      <div>
            <img src={logo} alt="Logo"/>
      </div>
      
    );
  }
}

export default Header;
