import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link } from "react-router-dom";
import YTIcon from '../images/youtube.png'
import TWIcon from '../images/twitch.png'

class NavRight extends Component {
  render() {
    return (
      <React.Fragment>
      <ListGroup>
        <Link href="/fmyt" to="/fmyt"><ListGroupItem>FishMissile<img alt="YT" width="16" height="16" src={YTIcon}></img></ListGroupItem></Link>
        <Link href="/fmtw" to="/fmtw"><ListGroupItem>FishMissile<img alt="TW" width="16" height="16" src={TWIcon}></img></ListGroupItem></Link>
      </ListGroup>
      <ListGroup>
        <Link href="/onyxyt" to="/onyxyt"><ListGroupItem>DarkOnyx<img alt="YT" width="16" height="16" src={YTIcon}></img></ListGroupItem></Link>
        <Link href="/onyxtw" to="/onyxtw"><ListGroupItem>DarkOnyx<img alt="TW" width="16" height="16" src={TWIcon}></img></ListGroupItem></Link>
      </ListGroup>
      <ListGroup>
        <Link href="/jcyt" to="/jcyt"><ListGroupItem>Joy_Collision<img alt="YT" width="16" height="16" src={YTIcon}></img></ListGroupItem></Link>
        <Link href="/jctw" to="/jctw"><ListGroupItem>Joy_Collision<img alt="TW" width="16" height="16" src={TWIcon}></img></ListGroupItem></Link>
      </ListGroup>
      </React.Fragment>
    );
  }
}

export default NavRight;
