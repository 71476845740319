import React from 'react';
import { Table } from 'react-bootstrap';

const Main = props => {

  return (
    <div className="swingintopfwd">
      <br />
      <div className="container">
        <Table align="center" striped bordered >
          <thead>
            <tr>
              <th>Welcome</th>
            </tr>
          </thead>
          <tbody >
            <tr><td>
              <p>The Jagged Commandos formed during the 2011 Killing Floor Halloween Sideshow event on <br />Killing Floor 1 and have been killing zeds together ever since.
              We also play many other co-op games such as  7 Days to Die, DeepRock Galactic, GTAV, many others and of course Killing Floor 2.
              We reside mostly on the east coast and currently use Discord to communicate. There are no requirements for joining as long as you have a mic and aren't a douchebag.
            We do however have one simple rule:<br /> Do not fuck up the patterns. Feel free to check our Steam page or our YouTube videos or contact <a target="new" href="https://steamcommunity.com/id/killerklovvn/">[JC]FishMissile</a> on Steam if you'd like to join us.
          </p></td></tr></tbody></Table>
      </div>
    </div>
  )

}

export default Main;
