import React from 'react';
import { Button, Table } from 'react-bootstrap';


class Servers extends React.Component {
    componentDidMount() {
        // const { query } = this.props;
        //query()
    }

    render() {
        const { query, loadstate } = this.props;
        const { serverinfo } = this.props;
        let map = null;
        let diff = null;
        let playerlist = null;
        let playerstable = null;
        let servername = null;
        let currentwave = null;
        let loading = loadstate;
        let infotable = null;
        console.log(loadstate)
        if (loading) {
            loading = <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only">Loading...</span></div>
        }
        if (serverinfo.loadfail) {
            loading = <div className="text-danger">Server query failed.</div>
        }
        if (serverinfo.mapname) {
            playerlist = serverinfo.players
            map = serverinfo.mapname
            diff = serverinfo.difficulty
            servername = serverinfo.servername
            currentwave = serverinfo.currentwave + "/" + serverinfo.maxwaves

            infotable = (<Table striped bordered>
                <tbody >
                    <tr>
                        <td>Server Name:</td>
                        <td colSpan="2">{servername}</td>
                    </tr>
                    <tr>
                        <td>Current Map:</td>
                        <td colSpan="2">{map}</td>
                    </tr>
                    <tr>
                        <td>Current Difficulty:</td>
                        <td colSpan="2">{diff}</td>
                    </tr>
                    <tr>
                        <td>Current Wave:</td>
                        <td colSpan="2">{currentwave}</td>
                    </tr>
                </tbody>
            </Table>)

            playerstable = (
                <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Time</th>
                                <th>Dosh</th>
                            </tr>
                        </thead>
                        <tbody >
                        {playerlist.map((player, index) => {
                            console.log("player" + player)
                            return (
                                <tr key={index}>
                                    <td colSpan="1">{player.name}</td>
                                    <td colSpan="1">{player.time ? Math.floor(player.time): null}</td>
                                    <td colSpan="1">{player.score}</td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }

        return (

            <div align="center" className="swingintopfwd">
                <br />
                <Button onClick={query}>Query</Button><br />{loading}
                <br />
                <div className="tablecenter">
                    {infotable}
                    {playerstable}
                </div>
            </div>
        )
    }
}

export default Servers;
