import React from 'react';
import { Table } from 'react-bootstrap';
import '../App.css'

const SteamUser = (props) => {
    let currentstatus = null;
    let recentgame1, recentgame2, recentgame3
    
    if (props.recentgames) {
        recentgame1 = (<img src={props.recentgames[0]} alt="game1"></img>)
        if (props.recentgames[1]) {
            recentgame2 = (<img src={props.recentgames[1]} alt="game2"></img>)
            if (props.recentgames[2]) {
                recentgame3 = (<img src={props.recentgames[2]} alt="game3"></img>)
            }
        }
    }

    if (props.userdata !== null) {
        console.log(props.userdata.playtime)
        switch (props.userdata.status) {
            case 0:
                currentstatus = (<div className="text-fail inline">Offline</div>)
                break;
            case 1:
                currentstatus = (<div className="text-success inline">Online</div>)
                break;
            case 2:
                currentstatus = (<div className="text-warning inline">Busy</div>)
                break;
            case 3:
                currentstatus = (<div className="text-warning inline">Away</div>)
                break;
            case 4:
                currentstatus = (<div className="text-warning inline">Snooze</div>)
                break;
            case 5:
                currentstatus = (<div className="text-success inline">Looking to trade</div>)
                break;
            case 6:
                currentstatus = (<div className="text-success inline">Looking to play</div>)
                break;
            default:
                currentstatus = null;
                break;
        }
        return (
            <div>
                <br />
                <Table cellPadding="0px" className="lilTable" align="center" striped>
                    <tbody >
                        <tr>
                            <td width="90px" rowSpan="5">
                               {props.userdata.avatar ? <a href={props.userdata.profile}><img className="profpic" src={props.userdata.avatar} alt="AVATAR"></img></a>:props.loadstate} 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="alignleft"><a href={props.userdata.profile} target="new">Steam Profile</a></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="alignleft">Status: {currentstatus}</div>
                            </td>
                        </tr>
                        {props.userdata.currentgame ?
                            <tr>
                                <td>
                                    <div className="alignleft ">Current Game: <div className="text-success inline">{props.userdata.currentgame}</div></div>
                                </td>
                            </tr> : null
                        }
                        <tr>
                            <td>
                                <div className="alignleft">KF2 Hours: {props.playtime}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div className="alignleft">Recent:
                                    {!props.loadstate ? recentgame1:null}
                                    {!props.loadstate ? recentgame2:null}
                                    {!props.loadstate ? recentgame3:null}
                                    {props.loadstate}
                                </div>
                            </td>
                        </tr>
                    </tbody></Table>
            </div>
        );
    } else {
        return (
            null
        )
    }



}

export default SteamUser
