import React from 'react';
import { Table } from 'react-bootstrap';

const OnyxTw = props => {

    return (
        <div className="swingintopfwd">
            <br />
            <div className="container">
                <Table align="center" striped bordered >
                    <thead>
                        <tr>
                            <th><a href="https://www.youtube.com/user/onyxlynx" >DarkOnyx on Twitch</a></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>
                            <iframe title="Onyx Twitch" src="https://player.twitch.tv/?channel=masterdarkonyx" scrolling="no" height="378" width="620"></iframe>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default OnyxTw;
