import React, { Component } from 'react';
import Header from './components/header'
import Navigation from './components/navigation'
import NavRight from './components/navright'
import Main from './components/main'
import MemberPage from './components/members'
import Servers from './components/servers'
import Gallery from './components/gallery'
import Discord from './components/discord'
import FmYt from './components/fmyt'
import FmTw from './components/fmtw'
import OnyxYt from './components/onyxyt'
import OnyxTw from './components/onyxtw'
import JcYt from './components/jcyt'
import JcTw from './components/jctw'
import axios from "axios";
import './App.css'

import { Grid, Row, Col, Table } from 'react-bootstrap';
import { BrowserRouter as Router, Route } from "react-router-dom";


class App extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
      serverdata: {},
      loadstate: false,
    };
    this.queryThisIp = this.queryThisIp.bind(this);
  }

  queryThisIp() {
    this.setState({
      loadstate: true,
    });
    console.log("Target IP: ");
    var querytargetvalue = "142.54.165.58";
    /*     var queryurl =
      "https://desolate-bayou-31888.herokuapp.com/query/" + querytargetvalue; */
    var queryurl = "https://desolate-bayou-31888.herokuapp.com/query/" + querytargetvalue;
    axios.get(queryurl)
      .then(response => {
        console.log(response.data[0]);
        console.log("response status: " + response.status);
        this.setState({
          loadstate: false,
          serverdata: {
            mapname: response.data[0].mapname,
            id: 1,
            difficulty: response.data[0].difficulty,
            gamemode: response.data[0].gamemode,
            servername: response.data[0].servername,
            currentwave: response.data[0].currentwave,
            maxwaves: response.data[0].maxwaves,
            players: response.data[1]
          },
        });
        console.log("new state: " + this.state.serverdata);
      })
      .catch(function (error) {
        // handle error
        this.setState({
          serverdata: {
            loadfail: true
          }
        });
        console.log(error);
      }.bind(this));
  }

  render() {
    return (
      <Router>
        <Grid>
          <Row className="show-grid">
            <Col smHidden={true} mdHidden={true} lg={2}>
            </Col>
            <Col xs={2} sm={3} lg={8} md={6}>
              <Header />
            </Col>
            <Col xs={1} smHidden={true} md={2} lg={2}>
            </Col>
          </Row>
          <Table bordered>
            <tbody>
              <tr className="banner">
                <th><center>JaggedCommandos.net</center></th>
              </tr>
              <tr>
                <td>
                  <Row className="show-grid">
                    <Col sm={3} md={2} xs={1}>
                      <Navigation />
                    </Col>
                    <Col sm={6} lg={8} md={8} xs={3}>
                      <center>
                        <Route path="/" exact component={Main} />
                        <Route path="/members/" component={MemberPage} />
                        <Route path="/servers/" render={props => <Servers
                          serverinfo={this.state.serverdata}
                          loadstate={this.state.loadstate}
                          query={this.queryThisIp} {...props} />} />
                        <Route path="/gallery/" component={Gallery} />
                        <Route path="/discord/" exact component={Discord} />
                        <Route path="/fmyt/" exact component={FmYt} />
                        <Route path="/fmtw/" exact component={FmTw} />
                        <Route path="/onyxyt/" exact component={OnyxYt} />
                        <Route path="/onyxtw/" exact component={OnyxTw} />
                        <Route path="/jcyt/" exact component={JcYt} />
                        <Route path="/jctw/" exact component={JcTw} />
                      </center>
                    </Col>
                    <Col sm={3} md={2} xs={1}>
                      <NavRight />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid>
      </Router>
    );
  }
}

export default App;
