import React from 'react';
import { Table } from 'react-bootstrap';
import SteamUser from './steamuser.js'
import axios from "axios";

import Members from "../members.json"

import '../App.css'
import '../css/icons.css'

class MemberPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userdata: null,
            recentgames: false,
            loadstate: null
        };
    }
    
    GetPlaytime(games) {
        console.log(games)
        for (var i = 0; i < games.length; i++) {
            //    console.log("Each Game: " + games[i].playtime_forever)
            if (games[i].appid === 232090) {
                console.log("playtime: " + games[i].playtime_forever)
                return Math.floor(games[i].playtime_forever / 60);
            }
        }
    }
    SteamQuery(selected) {
        this.setState({
            userdata: {
                avatar: null,
                status: null,
                profile: null,
                currentgame: null,
            }
        })
        this.setState({loadstate: <div className="spinner-border spinner-border-sm" role="status"><span className="sr-only">Loading...</span></div>})


        console.log("Passed Selected Value: " + selected)
        const playerarray = Object.keys(Members)
        const idarray = Object.values(Members)
        let match = null;
        for (let i = 0; i < playerarray.length; i++) {
            if (playerarray[i] === selected) {
                console.log(idarray[i])
                match = idarray[i]
            }
        }
        const key = "8C24F2E41FBCA360025E35A38D734892"
        //https://agile-oasis-87058.herokuapp.com
        //https://desolate-bayou-31888.herokuapp.com
     
        var recentgames = "https://desolate-bayou-31888.herokuapp.com/recent/" + key + "/" + match
        var usergames = "https://desolate-bayou-31888.herokuapp.com/games/" + key + "/" + match
        var queryurl = "https://agile-oasis-87058.herokuapp.com/info/" + key + "/" + match


        axios.get(queryurl)
            .then(response => {
                this.setState({
                    userdata: {
                        avatar: response.data.response.players[0].avatarmedium,
                        status: response.data.response.players[0].personastate,
                        profile: response.data.response.players[0].profileurl,
                        currentgame: response.data.response.players[0].gameextrainfo,
                    }
                })
                let fuck = response.data.response.players[0].appid
                console.log("players: " + fuck);
                console.log(response.data.steamid);



                axios.get(recentgames)
                    .then(response => {
                        let game1url, game2url, game3url
                        if (response.data.response.games) {
                            const game1id = response.data.response.games[0].appid
                            const game1hash = response.data.response.games[0].img_icon_url
                            game1url = `http://media.steampowered.com/steamcommunity/public/images/apps/${game1id}/${game1hash}.jpg`
                            this.setState({
                                recentgames: [game1url]
                            })
                            if (response.data.response.games[1]) {
                                const game2id = response.data.response.games[1].appid
                                const game2hash = response.data.response.games[1].img_icon_url
                                game2url = `http://media.steampowered.com/steamcommunity/public/images/apps/${game2id}/${game2hash}.jpg`
                                this.setState({
                                    recentgames: [game1url, game2url]
                                })
                                if (response.data.response.games[2]) {
                                    const game3id = response.data.response.games[2].appid
                                    const game3hash = response.data.response.games[2].img_icon_url
                                    game3url = `http://media.steampowered.com/steamcommunity/public/images/apps/${game3id}/${game3hash}.jpg`
                                    this.setState({
                                        recentgames: [game1url, game2url, game3url]
                                    })
                                }
                            }
                        } else {
                            this.setState({
                                recentgames: false
                            })
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });



                axios.get(usergames)
                    .then(response => {
                        const gameslist = response.data.response.games
                        console.log("games " + response.data.response.games[0].appid)
                        const timeplayed = this.GetPlaytime(gameslist)
                        console.log("Time Played: " + timeplayed)
                        this.setState({
                            playtime: timeplayed,
                            loadstate:null
                        })
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }
    render() {
        return (
            <center>
                <div className="swingintopfwd">
                    <SteamUser loadstate={this.state.loadstate} recentgames={this.state.recentgames} playtime={this.state.playtime} userdata={this.state.userdata} />
                    <br />
                    <Table align="center" className="lilTable" striped bordered >
                        <tbody>
                            <tr>
                                <td>Founder</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("FishMissile") }}>FishMissile</div></td>
                            </tr>
                            <tr>
                                <td>Founder</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Dr.Zoidburge") }}>Dr.Zoidburge</div></td>
                            </tr>
                            <tr>
                                <td>Founder</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Cavispecs") }}>Cavispecs</div></td>
                            </tr>
                            <tr>
                                <td>Admin</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("TheIncredibleGox") }}>Gox</div></td>
                            </tr>
                            <tr>
                                <td>Admin</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("DarkOnyx") }}>DarkOnyx</div></td>
                            </tr>
                            <tr>
                                <td>Admin</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("NinjaNick") }}>NinjaNick</div></td>
                            </tr>
                            <tr>
                                <td>Officer</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Joy_Collision") }}>Joy_Collision</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Skeith") }}>Skeith</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("MoaningMyrtle") }}>MBQToxicJizz</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Neokramer") }}>Neokramer</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Toxic Waste") }}>Toxic Waste</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Soulfire") }}>Soulfire</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Soulfire") }}>Soulfire</div></td>
                            </tr>
                            <tr>
                                <td>Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("Foodman") }}>Foodman</div></td>
                            </tr>
                            <tr>
                                <td>Honorary Member</td>
                                <td><div className="btn-link" onClick={() => { this.SteamQuery("=Fraggage") }}>=Fraggage</div></td>
                            </tr>
                        </tbody>
                    </Table>
                </div></center>
        )
    }
}

export default MemberPage;
