import React from 'react';
import { Table } from 'react-bootstrap';

const JcYt = props => {

    return (
        <div className="swingintopfwd">
            <br />
            <div className="container">
                <Table align="center" striped bordered >
                    <thead>
                        <tr>
                            <th><a href="https://www.youtube.com/channel/UCnvre6fGQPW2V6kqCvf6KoQ">Joy_Collision on Youtube</a></th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>
                            <iframe  title="Joy YT" width="560" height="315" src="https://www.youtube.com/embed/ZP6iles_8P0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default JcYt;
